<template>
  <div class="detail-section" :class="{ 'loading-size': isLoading }">
    <div class="m-2 text-center" v-if="isLoading">
      <font-awesome-icon
        :icon="['fa', 'circle-notch']"
        class="fa-spin m-auto spinner fa-5x egx-partner-color"
      />
    </div>
    <template>
      <ValidationObserver
        tag="form"
        @submit.prevent=""
        ref="prqSave"
        class="detail-section-form"
        v-show="!isLoading"
      >
        <NoConfiguredPRQs v-if="!fetchedData.length && !isLoading" :stepConfiguration="stepConfiguration"/>
        <div v-for="section in fetchedData" :key="section.id" v-else>
          <prq :section="section" @answerList="addToAnswerList" v-if="section.question_type === 'Question' && isPrq"/>
          <attachment :section="section" @answerList="addToAnswerList" v-if="section.question_type === 'Attachment' && isAttachment"/>
        </div>
        <step-button
          :steps-response="stepsResponse"
          @navigate="navigate"
          @save-data="saveAnswer"
          :data-save-required="true"
          v-if="!isLoading"
          :display-save-changes-btn="isPrq && query.page !== 'tc'"
        />
      </ValidationObserver>
    </template>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import Prq from './Prqs.vue';
import Attachment from './Attachment.vue';
import GroupQuestion from '../../configurations/settings/group-questions.json';
import StepButton from '@/components/project/stepButton.vue';
import redirectPath from '@/shared/mixins/redirect-path';
import NoConfiguredPRQs from './NoConfiguredPRQs.vue';
import projectMixins from '@/shared/mixins/projectMixins';

export default {
  name: 'DetailSection',

  components: {
    StepButton,
    Multiselect,
    Prq,
    Attachment,
    NoConfiguredPRQs,
  },

  mixins: [redirectPath, projectMixins],

  props: {
    content: Object,
    stepsResponse: Object,
    navigate: Function,
    projectId: String,
    projectStatus: String,
    stepConfiguration: Object,
    fulfillSyncDetails: Object,
  },

  data() {
    return {
      responses: Object,
      query: this.$route.query,
      errMsg: '',
      isLoading: true,
      prqData: GroupQuestion,
      fetchedData: [],
      allAnswerList: {},
    };
  },

  created() {
    let params = { ...this.query };
    params.status = params.status || this.projectStatus;
    params.project_id = params.project_id || this.projectId;

    this.$store.dispatch('fulfill_integrate/get', params).then(() => {
      console.log('Success!');
      this.fetchedData = this.$store.state.fulfill_integrate.integrateData;

      this.fetchedData =
        this.fetchedData.sort(
          (a, b) => a.sorting_tag.localeCompare(b.sorting_tag, 'en', { numeric: true })
        );
      this.isLoading = false;
    }).catch((error) => {
      console.log(error.message);
      this.$notify({
        group: 'notice',
        text: error.message,
      });
    });
  },

  computed: {
    isPrq() {
      return this.query.type === 'prq';
    },

    isAttachment() {
      return this.query.type === 'attachment';
    },

    saveChangesButton() {
      return this.fulfillSyncDetails?.pages?.portal_steps?.save_changes_button;
    },
  },

  methods: {
    saveAnswer(step) {
      const project_id = this.$route.params.id;
      this.isLoading = true;
      this.$store.dispatch('fulfill_integrate/get_project_selected', { project_id })
        .then((response) => {
          if(this.locked(response.project)) {
            this.$notify({
              group: 'notice',
              text: 'This project is locked in the portal.',
            });
            this.$router.push('/applications');
          } else {
            if (step === 'saveChanges') {
              this.postData(step);
              return;
            }

            this.$refs.prqSave.validate()
              .then((isValid) => {
                if (isValid) {
                  if (this.query.type === 'attachment') {
                    this.isLoading = true;
                    this.$notify({
                      group: 'notice',
                      text: this.fulfillSyncDetails.messages?.documents_update?.success,
                      duration: 5000,
                    });
                    this.isLoading = false;

                    if (step === 'nextStep') {
                      this.navigate();
                    } else if (step === 'linkStep') {
                      this.$router.push(this.stepsResponse.add_btn_url);
                    }

                    return;
                  }

                  this.postData(step);
                } else {
                  console.log('Not Validated.');
                  this.isLoading = false;

                  this.$notify({
                    group: 'notice',
                    text: this.fulfillSyncDetails.messages.validations,
                    duration: 5000,
                  });

                  this.$nextTick(() => {
                    const hasError = this.$el.querySelector('.has-error');
                    if (hasError) {
                      hasError.scrollIntoView({ block: 'center', behavior: 'smooth' });
                    }
                  });
                }
              })
          }
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
        })
    },

    addToAnswerList(groupId, answerObject) {
      if(this.allAnswerList[groupId] == null){
        this.allAnswerList[groupId] = answerObject;
      } else {
        this.allAnswerList[groupId] = { ...this.allAnswerList[groupId], ...answerObject };
      }
    },

    postData(step){
      this.isLoading = true;

      const data = {
        answerList: this.allAnswerList,
        details: { ...this.query, project_id: this.projectId }
      }

      this.$store.dispatch('fulfill_integrate/post', data, this).then((response) => {
        this.$notify({
          group: 'notice',
          text: response.message,
          duration: 5000,
        });

        if (step === 'nextStep') {
          this.navigate();
        } else if (step === 'linkStep') {
          this.redirectPath(this.stepsResponse.add_btn_url);
        } else if (step === 'saveChanges') {
          this.redirectPath(this.saveChangesButton?.url || '/applications');
        }
      }).catch((error) => {
        console.log(error.message);
        this.isLoading = false;
        this.$notify({
          group: 'notice',
          text: error.message,
        });
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.loading-size {
  min-width: 70rem;
}
@media (max-width: 768px) {
  .loading-size {
    min-width: 10rem;
  }
}
</style>
