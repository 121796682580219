<template>
  <div class="container">
    <loading-screen v-if="isLoading" :content="loadingScreenContent" />
  </div>
</template>

<script>
import LoadingScreen from "@/components/LoadingScreen.vue";
import router from "@/router";

export default {
  name: 'fetch-account-details',

  components: {
    LoadingScreen
  },

  data() {
    return {
      isLoading: true,
    };
  },

  created() {
    if (this.queryIsNotSearchDetail && this.detailVerificationStep) {
      router.push({ name: 'UserEdit' });
    } else {
      this.$store.dispatch('users/searchAndFetchAccountDetails')
        .then((response) => {
          if (response.type === 'user_synced' || response.type === 'account_not_found' || response.type === 'more_than_one_found') {
            this.$store.dispatch('users/get').then(() => {
              this.isLoading = false;
              router.push({ name: 'UserEdit' });
            });
          } else {
            this.isLoading = false;
            router.push({ name: 'buildings-list' });
          }
        })
        .catch((error) => {
          this.$notify({
            group: 'notice',
            text: error.response.data.errors.title,
          });

          this.$store.dispatch('auth/signOut').then(() => {
            this.isLoading = false;
            router.push({ name: 'sign-in' });
          });
        });
    }
  },

  computed: {
    partner() {
      return this.$store.state.partners.currentPartner;
    },

    queryIsNotSearchDetail() {
      return this.$route.query.isSearchDetail !== true;
    },

    detailVerificationStep() {
      return this.$store.state.users.currentUser.extra_info?.verification_step === 'detail_search';
    },

    loadingScreenContent() {
      if (this.partner.fulfill_settings) {
        return this.partner.fulfill_settings.account_search_loading_screen;
      } else {
        return {
          title: {
            text: 'Searching for your account',
            class: ['egx-partner-color'],
            style: 'color: var(--egx-partner-color)',
          },
          description: {
            text: 'Please wait while we look if you have an account with your profile data.'
          },
          spinner:{
            class: ['egx-partner-color']
          },
          cta: {
            text: 'If this page doesn\'t refresh, please try again.',
            class: ['egx-partner-color'],
            path_name: 'fetch-account-details'
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
