<template>
  <div>
    <ApplicationShow :step-num="step" />
  </div>
</template>

<script>
import ApplicationShow from '../components/ApplicationShow.vue';

export default {
  components: {
    ApplicationShow
  },
  data() {
    return {
      step: 0
    };
  }
}
</script>
