<template>
  <div class="d-flex justify-content-end mt-4">
    <button class="btn btn-egx mr-4" @click="prevButtonFlow" v-if="displayPrevious">
      Previous
    </button>
    <button class="btn btn-egx mr-4" @click="cancelButtonFlow">
      {{this.stepsResponse['cancel_btn_lbl'] || this.cancelButton?.label || 'Cancel'}}
    </button>
    <button class="btn btn-egx mr-4" @click="saveChangesButtonFlow" v-if="displaySaveChangesBtn">
      {{this.saveChangesButton?.label || 'Save Changes'}}
    </button>
    <button class="btn btn-egx mr-4" @click="addButtonFlow" v-if="this.stepsResponse.add_btn_url">
      {{this.stepsResponse['add_btn_label'] || 'Save &amp; Return to My Applications Page'}}
    </button>
    <button class="btn btn-egx mr-4" @click="nextStepFlow">
      {{this.stepsResponse['next_btn_label'] || 'Save &amp; Next Step'}}
    </button>
  </div>
</template>

<script>
import redirectPath from '@/shared/mixins/redirect-path';

export default {
  props: {
    stepsResponse: {
      type: Object,
      default: {},
    },
    dataSaveRequired: false,
    displaySaveChangesBtn: false,
  },

  mixins: [redirectPath],

  computed: {
    displayPrevious(){
      return this.stepsResponse.hide_prev_btn === 'false' && Number(this.stepsResponse.step_num) > 1;
    },

    portalStepPageSetup() {
      return this.$store.state.partners.currentPartner.settings.fulfill_sync_details?.pages?.portal_steps;
    },

    cancelButton() {
      return this.portalStepPageSetup?.cancel_button;
    },

    saveChangesButton() {
      return this.portalStepPageSetup?.save_changes_button;
    },
  },

  methods: {
    prevButtonFlow(){
      this.$emit('navigate', 'ps');
    },

    nextStepFlow(){
      if (this.dataSaveRequired){
        this.$emit('save-data', 'nextStep');
      } else {
        this.$emit('navigate', 'ns');
      }
    },

    addButtonFlow(){
      // if (this.dataSaveRequired){ this.$emit('save-data', 'linkStep'); }
      this.redirectPath(this.stepsResponse.add_btn_url);
    },

    cancelButtonFlow(){
      this.redirectPath(this.stepsResponse.cancel_btn_url || this.cancelButton.url || '/applications');
    },

    saveChangesButtonFlow(){
      this.$emit('save-data', 'saveChanges');
    },
  }
};
</script>

<style scoped lang="scss">
</style>
